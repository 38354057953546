function DisableRightClickAndDraggingImages() {
  $("img").mousedown(function(e){
    e.preventDefault()
  });
  $("img").on("contextmenu",function(e){
    return false;
  });
  $(".project-top-container__image").on("contextmenu",function(e){
    return false;
  });
}
export default DisableRightClickAndDraggingImages;
