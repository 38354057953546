function SupportProject () {
  const support_button = document.querySelector('.support-project');
  if (typeof(support_button) != 'undefined' && support_button != null) {
    support_button.addEventListener("click", (event) => {
      var project_id = support_button.dataset.project;

      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      fetch(
        '/support_project',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token
          },
          body: JSON.stringify({
            project_id: project_id
          })
        }
      ).then(
        (response) => {
          response.json().then((data) => {
            swal("Success", data.message, "success");
            if(data.type == "join") {
              support_button.innerHTML = 'Leave project';
              $(".user-list .user-list__info").remove();
              $(".user-list").append('<a class="my-card" href="#"><li class="user-list__user">' + data.user_card + '</li></a>');
            } else {
              support_button.innerHTML = 'Support project';
              $(".user-list .my-card").remove();
            }
          });
        },
        (error) => {
          console.log(error);
        }
      )
    });
  }
}

export default SupportProject;
