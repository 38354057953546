function addEventListenerDonationButton() {
  const donationButtonStorageName = 'donationButtonVisible';
  const donationButton = document.querySelector('.donation-button');
  const donationButtonOpenedClass = 'donation-button--opened';
  const donationButtonClosedClass = 'donation-button--closed';
  const donationButtonToggleButton = document.querySelector('.donation-button__close-button');

  let donationButtonToggleVisibility = (donationButton) => {
    if (donationButtonIsOpen(donationButtonStatus())) {
      localStorage.setItem(donationButtonStorageName, false);
      donationButton.classList.remove(donationButtonOpenedClass);
      donationButton.classList.add(donationButtonClosedClass);
    } else {
      localStorage.setItem(donationButtonStorageName, true);
      donationButton.classList.add(donationButtonOpenedClass);
      donationButton.classList.remove(donationButtonClosedClass);
    }
  }

  const donationButtonStatus = () => {
    return localStorage.getItem(donationButtonStorageName);
  }

  let donationButtonIsOpen = (status) => {
    return (
      status == 'true' ||
      status == undefined ||
      status == '' ||
      status == null
    );
  }

  donationButtonToggleButton.addEventListener('click', e => {
    e.preventDefault();
    donationButtonToggleVisibility(donationButton);
  });

  if(donationButtonIsOpen(donationButtonStatus())) {
    donationButton.classList.add(donationButtonOpenedClass);
    donationButton.classList.remove(donationButtonClosedClass);
  } else {
    donationButton.classList.add(donationButtonClosedClass);
    donationButton.classList.remove(donationButtonOpenedClass);
  }
}

export default addEventListenerDonationButton;
