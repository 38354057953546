import jQuery from 'jquery';
window.$ = jQuery;
import 'whatwg-fetch';

import NavCollapse from '../../assets/javascripts/frontend/nav-collapse'
import ImageClick from '../../assets/javascripts/frontend/image-click'
import ShowMoreProjectsCarousel from '../../assets/javascripts/frontend/show-more-projects-carousel'
import SupportProject from '../../assets/javascripts/frontend/support-project'
import tidyupProjectDetails from '../../assets/javascripts/frontend/tidyup-project-details'
import CheckboxWidget from '../../assets/javascripts/frontend/checkbox-widget'
import DisableRightClickAndDraggingImages from '../../assets/javascripts/frontend/disable-right-click-and-dragging-images'
import addEventListenerDonationButton from '../../assets/javascripts/frontend/donation-button';
import '../../assets/javascripts/frontend/cookie-eu'

window.onload = function () {
  addEventListenerDonationButton();
  DisableRightClickAndDraggingImages();
  NavCollapse();

  SupportProject();
  ShowMoreProjectsCarousel();

  ImageClick();

  tidyupProjectDetails();

  // attachEventHandlers();

  const widget = new CheckboxWidget();
  widget.attachEventHandlers();
  widget.projectNavigation();
};
window.onmouseover = function() {
  DisableRightClickAndDraggingImages();
}
