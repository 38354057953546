function ImageClick() {
  const open_button = document.querySelector('.open-gallery');
  if (typeof(open_button) != 'undefined' && open_button != null) {
    open_button.addEventListener("click", (event) => {
      var project_id = open_button.dataset.project;
      var image_id = open_button.dataset.image;
      OpenGallery(project_id, image_id);
    });
  }
  const images = document.querySelectorAll('.image-gallery * img');
  if (typeof(images) != 'undefined' && images != null) {
    images.forEach((image, index) => {
      image.addEventListener("click", (event) => {
        var project_id = image.dataset.project;
        var image_id = image.dataset.image;
        OpenGallery(project_id, image_id);
      });
    });
  }
}
function OpenGallery(project_id, image_id) {
  $(".content-wrapper").append('<div class="blur-img"></div>');

  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch(
    '/gallery',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        project_id: project_id,
        image_id: image_id
      })
    }
  ).then(
    (response) => {
      response.text().then((text) => {
        $("body").append(text);
      })
    },
    (error) => {
      console.log(error);
    }
  )
}

export default ImageClick;
