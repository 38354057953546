function tidyupProjectdetails() {
  const projectdetails = document.querySelectorAll('.project-details-inbox *');
  projectdetails.forEach((object) => {
    var object = $('iframe, img', object);
    if(object.length > 0) { object.unwrap(); }
  });

  // Open links in new tab
  var anchors = document.querySelectorAll('.trix-content a');
  for (var i=0; i<anchors.length; i++){
    anchors[i].setAttribute('target', '_blank');
  }
}

export default tidyupProjectdetails;
