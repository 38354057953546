function NavCollapse() {
  const button = document.querySelector(".navbar-toggler"); if (!button) return false;

  button.addEventListener('click', (event) => {
    if($(".navbar-toggler--active").length) {
      $(button).removeClass("navbar-toggler--active");
      $(".header").removeClass("header--active");
      $(".header .header__nav").removeClass("header__nav--active");
      $(".main-nav").removeClass("main-nav--active");
    }
    else {
      $(button).addClass("navbar-toggler--active");
      $(".header").addClass("header--active");
      $(".header .header__nav").addClass("header__nav--active");
      $(".main-nav").addClass("main-nav--active");
    }
  });
}

export default NavCollapse;
