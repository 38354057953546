class CheckBoxWidget {
  constructor() {
    this.categories = [];
    this.searchString = "";
    this.populateCategoriesFromDom();
  }

  populateCategoriesFromDom() {
    const container = document.querySelector('.project-search-vertical');
    if (!container) { return; }
    const params = container.dataset.params;

    const checkboxes = document.querySelectorAll('.category-checkbox');
    if(checkboxes) {
      checkboxes.forEach((checkbox) => {
        this.categories.push(checkbox.dataset.index);
      }
    )}
  }

  loadFilteredProjects(page) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.searchString = "";

    fetch(
      '/projectsfetch',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token
        },
        body: JSON.stringify({
          categories: this.categories,
          page: page
        })
      }
    ).then(
      (response) => {
        response.json().then((data) => {
          $(`.projects-list__notfound`).remove();
          if(!data.projects.length) {
            $('.projects-list').append(`<li class="projects-list__notfound"><i class="fas fa-sad-tear"></i><span>No projects found</span></li>`);
          }
          for(let i=0; i<7; i++) {
            $(`.project_${i + 1}`).remove();
          }
          data.projects.forEach((project, index) => {
            const li = $(`<li class="project_${index + 1}">${project}</li>`);
            $('.projects-list').append(li);
          })

          $(".page-nav").html(data.page_nav);
          this.projectNavigation();
        });
      },
      (error) => {
        console.log(error);
      }
    )
  }

  projectNavigation() {
    const buttons = document.querySelectorAll('.pagination .page, .pagination .prev-page');
    if(buttons) {
      buttons.forEach((button) => {
        button.addEventListener("click", (event) => {
          const page = button.dataset.page;

          if(this.searchString.length > 0) {
            this.loadSearchedProjects(page);
          } else {
            this.loadFilteredProjects(page);
            }
          document.querySelector(".projects-overview-wrapper").scrollIntoView();
        });
      }
    )}
  }

  loadSearchedProjects(page) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(
      '/projectsfetch',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token
        },
        body: JSON.stringify({
          searchString: this.searchString,
          page: page
        })
      }
    ).then(
      (response) => {
        response.json().then((data) => {
          $(`.projects-list__notfound`).remove();
          if(!data.projects.length) {
            $('.projects-list').append(`<li class="projects-list__notfound"><i class="fas fa-sad-tear"></i><span>No projects found</span></li>`);
          }
          for(let i=0; i<7; i++) {
            $(`.project_${i + 1}`).remove();
          }
          data.projects.forEach((project, index) => {
            const li = $(`<li class="project_${index + 1}">${project}</li>`);
            $('.projects-list').append(li);
          })
          $(".page-nav").html(data.page_nav);
          this.projectNavigation();
        });
      },
      (error) => {
        console.log(error);
      }
    )
  }

  attachEventHandlers() {
    const checkboxes = document.querySelectorAll('.category-checkbox');
    const filterbutton = document.querySelector('#problem-filter');
    const search = document.querySelector(".input--search");

    checkboxes.forEach((checkbox, index) => {
      checkbox.addEventListener("change", (event) => {
        var filter = true;
        checkboxes.forEach((checkbox, index) => {
          if(!checkbox.checked) {
            filter = false;
          }
        });
        if(filter) {
          filterbutton.checked = true;
          $(".category__filter").removeClass("category__filter--inactive");

        } else {
          filterbutton.checked = false;
          $(".category__filter").addClass("category__filter--inactive");

        }
        const checkbox = event.target;
        const checkbox_index = checkbox.dataset.index;

        if(checkbox.checked) {
         this.categories.push(checkbox_index);
        } else {
          for( var i = 0; i < this.categories.length; i++){
            if ( this.categories[i] === checkbox_index) {
              this.categories.splice(i, 1);
            }
         }
        }
        this.loadFilteredProjects();
        search.value = "";
      })
    });

    if (!filterbutton) { return; }
    filterbutton.addEventListener("click", (event) => {
      const checkboxes = document.querySelectorAll('.category-checkbox')
      if(filterbutton.checked) {
        $(".category__filter").removeClass("category__filter--inactive");
        if(checkboxes) {
          checkboxes.forEach((checkbox) => {
            checkbox.checked = true;
            this.categories.push(checkbox.dataset.index);
          }
        )}
      } else {
        $(".category__filter").addClass("category__filter--inactive");
        if(checkboxes) {
          checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
            this.categories.length = 0;
          }
        )}
      }
      search.value = "";
      this.loadFilteredProjects();
    });

    if(!search) { return; }
    search.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        const checkboxes = document.querySelectorAll('.category-checkbox');
        const filterbutton = document.querySelector('#problem-filter');
        $(".category__filter").addClass("category__filter--inactive");
        filterbutton.checked = false;
        checkboxes.forEach((checkbox, index) => {
          checkbox.checked = false;
        });
        this.searchString = search.value;
        if(this.searchString.length == 0) {
          filterbutton.click();
        } else {
          this.loadSearchedProjects();
        }
      }
    });
  }
  setCategoryinDOM(index, state) {
    if (state) {
      document.querySelector(`.category-${index}`).checked = true;
    } else {
      document.querySelector(`.category-${index}`).checked = false;
    }
  }
}

export default CheckBoxWidget;
